import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

const container = document.getElementById("root")!;
const root = createRoot(container);

const enum AppRoutes {
  Home = "/",
  Meter = "/m/:meter",
  Gateway = "/g/:gateway",
  Cylinder = "/c/:cylinder",
}

const redirectTarget =
  process.env.REACT_APP_STAGE === "development"
    ? "https://gaas-test.tapdo.net/"
    : "https://gaas.westfalen.com/";

const router = createBrowserRouter([
  {
    path: AppRoutes.Home,
    element: <App />,
  },
  {
    path: AppRoutes.Meter,
    loader: async ({ params }) => {
      return redirect(
        `${redirectTarget}digidrumi/inspect-meter/${params.meter}`
      );
    },
  },
  {
    path: AppRoutes.Cylinder,
    loader: async ({ params }) => {
      return redirect(
        `${redirectTarget}public/inspect-bottle/${params.cylinder}`
      );
    },
  },
  {
    path: AppRoutes.Gateway,
    loader: async ({ params }) => {
      return redirect(redirectTarget);
    },
  },
  {
    path: "*",
    loader: async ({ params }) => {
      return redirect(redirectTarget);
    },
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
